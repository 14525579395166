@use 'global' as *;
.container {
  background-color: var(--color-warm-gray);
  padding-bottom: 80px;

  .button_container {
    display: flex;
    justify-content: center;
  }
}
